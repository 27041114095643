* {
	font-family: "Roboto", sans-serif;
	box-sizing: border-box;
}
*::-webkit-scrollbar {
	display: none;
}
body {
	margin: 0;
	font-family: "Roboto", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
button {
	border: none;
	background: none;
	cursor: pointer;
	&:active {
		outline: none;
	}
}
ul {
	padding: 0;
	margin: 0;
	list-style: none;
}
